<template>
  <div :class="{ loading, horoscope: true }">
    <div id="horoscope-el"></div>
    <div class="content">
      <div class="content__item">
        <button class="content__button text-white" ref="btn" @click="enter">Consult the vibeagon</button>
      </div>
      <div class="content__item">
        <h3 class="m-0 text-lg font-medium text-gray-700" ref="label1">Your energy says you belong in the</h3>
        <h1 class="content__title" ref="title" :style="`--color-vibe: #${vibe.color};`">
          <span v-for="(letter, idx) in vibe.name" :class="`char${idx + 1}`" :key="idx">{{ letter }}</span>
          <span>&nbsp;</span>
          <span>v</span>
          <span>i</span>
          <span>b</span>
          <span>e</span>
        </h1>
        <p class="mt-2 text-lg font-medium text-gray-600" ref="label2">or not, I'm a sign, not a cop</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import * as explosion from './ico'
import { TweenMax, TimelineMax, Expo, Quart } from './TweenMax.min'
import vibes from '@/components/vibes'

const btn = ref(null)
const label1 = ref(null)
const label2 = ref(null)
const title = ref(null)
const loading = ref(true)

// Randomly pick a vibe to use
const getVibe = () => {
  let vibeIdx = window.localStorage.getItem('_vibe')
  if (vibeIdx) return vibes[vibeIdx]

  vibeIdx = Math.floor(Math.random() * vibes.length)
  window.localStorage.setItem('_vibe', vibeIdx)

  return vibes[vibeIdx];
}
const vibe = ref(getVibe())

let animation;

// Set up variables for animation
let targetMouseX = 0, mouseX = 0, targetMouseY = 0, mouseY = 0, ta = 0, taY = 0, isOpen = false;
const sign = n => n === 0 ? 1 : n/Math.abs(n);   

// Handle mouse and touch events for parallax
const globalMouseMove = e => {
  targetMouseX = 2*(e.clientX - animation.width/2)/animation.width
  targetMouseY = 2*(e.clientY - animation.height/2)/animation.height
}
const globalTouchMove = e => {
  targetMouseX = ( e.touches[0].clientX / animation.width ) * 2 - 1;
  targetMouseY = ( e.touches[0].clientY / animation.height ) * 2 - 1;
}
const btnMouseEnter = () => {
  if (isOpen) return
  new TimelineMax().to(animation.camera.position, 1, {
    z: 5.5,
    ease: Expo.easeOut
  }, 0);
}
const btnMouseLeave = () => {
  if (isOpen) return
  new TimelineMax().to(animation.camera.position, 1, {
    z: 7,
    ease: Expo.easeOut
  }, 0);
}

// Render loop
const draw = () => {
  if (animation) {
    mouseX += (targetMouseX - mouseX)*0.05;
    mouseY += (targetMouseY - mouseY)*0.05;
    ta = Math.abs(mouseX);
    taY = Math.abs(mouseY);
    animation.scene.rotation.x = Math.PI/2 - taY*(2 - taY)*Math.PI * sign(mouseY);
    animation.scene.rotation.y = Math.PI/2 - ta*(2 - ta)*Math.PI * sign(mouseX);
    animation.scene.rotation.z = Math.PI/2 - ta*(2 - ta)*Math.PI * sign(mouseX);
  }
  window.requestAnimationFrame(draw)
}

// Enter the vibe
const enter = () => {
  isOpen = true
  const titleLetters = [...title.value.querySelectorAll('span')]

  new TimelineMax()
  .to(btn.value, 0.3, {
    opacity: 0,
    ease: Expo.easeOut,
    complete: () => TweenMax.set(btn.value, {'pointer-events' : 'none'})
  })
  .to(animation.camera.position, 0.5, {
    z: 10,
    ease: Expo.easeIn
  }, 0)
  .to(animation.settings, 4, {
    progress: 2,
    ease: Expo.easeOut
  }, 0.4)
  .to([label1.value, label2.value], 1, {
    opacity: 1,
    startAt: {scale: 0},
    scale: 1,
    ease: Expo.easeOut
  }, 0.4)
  .staggerTo(titleLetters.sort(() => Math.round(Math.random())-0.5), 1, {
    opacity: 1,
    startAt: {scale: 0},
    scale: 1,
    ease: Expo.easeOut
  }, 0.04 , 0.4)
  .to(animation.settings, 1, {
    progress: 0,
    ease: Quart.easeInOut
  }, 4)
  .to(animation.camera.position, 1, {
    z: 7,
    ease: Quart.easeInOut
  }, 4)
  .staggerTo([label1.value, label2.value], 1, {
    opacity: 0,
    scale: 0,
    ease: Expo.easeInOut
  }, 0.06, 4)
  .staggerTo(titleLetters, 1, {
    opacity: 0,
    scale: 0,
    ease: Expo.easeInOut
  }, 0.04 , 4)
  .to(btn.value, 0.3, {
    opacity: 1,
    ease: Expo.easeOut,
    complete: () => TweenMax.set(btn.value, {'pointer-events' : 'auto'})
  }, 4.9)
  .call(function() {
    // nothing
    isOpen = false
  }, null, null, 4.4);
}

// Hook up mouse movement events
onMounted(() => {
  title.value.style.opacity = 1;
  animation = new explosion.default(
    'horoscope-el', // id of DOM el
    {
      surface: '475569',
      inside: vibe.value.color,
      background: 'f1f5f9',
      onLoad: ()=>{
        loading.value = false
      }
    },
  );

  draw()
  // Global (camera position)
  document.addEventListener('mousemove', globalMouseMove)
  document.addEventListener('touchmove', globalTouchMove)
  btn.value.addEventListener('mouseenter', btnMouseEnter)
  btn.value.addEventListener('mouseleave', btnMouseLeave)
})
onUnmounted(() => {
  document.removeEventListener('mousemove', globalMouseMove)
  document.removeEventListener('touchMove', globalTouchMove)
  btn.value.removeEventListener('mouseenter', btnMouseEnter)
  btn.value.removeEventListener('mouseleave', btnMouseLeave)
})
</script>

<style lang="scss" scoped>
.text-yacht {
  color: #a855f7;
}


.horoscope {
  --color-text: #e0e0e0;
  --color-text-secondary: #5e6262;
  --color-bg: #151616;
  --color-link: #5e6262;
  --color-link-hover: #e0e0e0;
  --color-date: #e0e0e0;

  position: relative;

  &.loading::before {
    content: '';
    position: fixed;
    z-index: 100000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f1f5f9;
  }

  &.loading::after {
    content: '';
    position: fixed;
    z-index: 100000;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    pointer-events: none;
    border-radius: 50%;
    opacity: 0.4;
    background: #64748b;
    animation: loaderAnim 0.7s linear infinite alternate forwards;
  }

  a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
  }

  a:hover,
  a:focus {
    color: var(--color-link-hover);
    outline: none;
  }

  #horoscope-el {
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }

  canvas {
    display: block;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: 100%;
    justify-items: center;
    align-items: center;
    text-align: center;
  }

  .content__item {
    grid-area: 1 / 1 / 2 / 2;
  }

  .content__item > * {
    opacity: 0;
    pointer-events: none;
  }

  .content__title {
    font-weight: 700;
    font-size: 9vw;
    margin: 0;
    color: var(--color-text);
  }

  .content__item .content__title {
    opacity: 0;
  }

  .content__title span {
    display: inline-block;
    opacity: 0;
    font-weight: 900;
    font-smoothing: antialiased;
    letter-spacing: -5px;
    text-shadow: 0 0 5px var(--color-vibe);
    color: var(--color-vibe);
  }

  .content__date {
    font-size: 3rem;
    font-weight: 700;
    margin: 1rem 0 0 0;
    color: var(--color-date);
  }

  .content__inner {
    text-align: left;
    list-style: none;
    padding: 0;
    margin: 2rem 0 0 0;
    display: block;
  }

  .content__inner-item {
    display: inline;
    white-space: nowrap;
    font-weight: 700;
  }

  .content__inner-item--date {
    display: block;
    margin: 0 0 1.5rem;
    font-size: 2rem;
  }

  .content__inner-item:nth-child(3n-1) {
    font-weight: 100;
    text-transform: uppercase;
  }

  .content__inner-item:nth-child(3n) {
    font-weight: 200;
  }

  .content__button {
    padding: 0;
    margin: 0;
    background: none;
    border: 0;
    color: var(--color-text);
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 700;
    position: relative; 
  }

  .content__button::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: currentColor;
    left: 0;
    top: 100%;
    opacity: 0;
    transition: transform 0.3s, opacity 0.3s;
    transform: scale3d(0,1,1);
    transform-origin: 0% 50%;
  }

  .content__button:hover::after {
    opacity: 1;
    transition: transform 0.3s, opacity 0.1s;
    transform: scale3d(1,1,1);
  }

  .content__item .content__button {
    opacity: 1;
    pointer-events: auto;
  }

  .content__button:focus {
    outline: none;
  }
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5,0.5,1);
  }
}
</style>