import yachtImg from '@/assets/vibes/yacht.png'
import tradingImg from '@/assets/vibes/trading.png'
import nightclubImg from '@/assets/vibes/nightclub.png'
import moonImg from '@/assets/vibes/moon.png'
import dojoImg from '@/assets/vibes/dojo.png'
import degenImg from '@/assets/vibes/degen.png'
import atlantisImg from '@/assets/vibes/atlantis.png'
import futureImg from '@/assets/vibes/3022.png'

export default [
  {
    name: 'Yacht',
    color: 'a855f7', // purple
    img: yachtImg,
    desc: 'Your journey starts here, everyone has a vibe and it’s time to find yours. For now, step aboard our yacht, the SS Degen and enjoy the cool ocean breeze by the cabana as you stake your coins into lambos.'
  },
  {
    name: 'Nightclub',
    color: 'fbbf24', // amber
    img: nightclubImg,
    desc: 'Drinks, check. Models, check. Vibe, check. If you’ve ever been blackout drunk before, said you weren’t going to do it again, then did it again, you’re in the right place. Keep your vibe on the edge and live your best life here.'
  },
  {
    name: 'Moon',
    color: '64748b', // slate
    img: moonImg,
    desc: 'Forget the stars, you’re heading on a one way trip to the moon. It may be cold up here, but you’ll realize your life is complete as you drift around the lunar surface on your Ferrari rover, staring at the stacks you’ve been making all year.',
  },
  {
    name: 'Dojo',
    color: 'f97316',  // orange
    img: dojoImg,
    desc: 'For a small monthly ETH fee The Metaverse gods will follow you on Twitter, shill your latest project, and save you 30% on your car\'s extended warranty. We promise they’re real and not just robots running behind a curtain. *Beep* huh.',
  },
  {
    name: 'Trading',
    color: 'ef4444', // red
    img: tradingImg,
    desc: 'There are probably 100,000 analysts saying you’re ngmi right now. Trust me bro, they’re all wrong you got this. If you only see life in red or green candles this vibe will always take care of you, or take away your rent, who knows.',
  },
  {
    name: 'Atlantis',
    color: '3b82f6', // blue
    img: atlantisImg,
    desc: 'Ever had an underwater blowwy from a mermaid? My spiritual guide claims he has, and with his 25k Twitter followers how could you not believe him. Gamble your Doge with Poseiden whilst you’re fed grapes by rug hunting Sirens here.'
  },
  {
    name: 'Degen',
    color: '84cc16', // lime
    img: degenImg,
    desc: 'Are your favourite numbers 69 and 420? Do you watch old Snoop videos thinking “I could outsmoke that guy”? Looks like you found your vibe. Let’s roll up and watch some ancient aliens on repeat whilst you argue with your sofa about the politics of small Islands.',
  },
  {
    name: '3022',
    color: 'ec4899', // pink
    img: futureImg,
    desc: 'If you absolutely love neon, boy do we have a vibe for you. The last 1000 years turned the world into a dystopia, but you’re all set in your luxury apartment living out your fantasies in the MetaVerse and drinking virtual Mountain Dew, in 3022.'
  },
  {
    name: 'Casino',
    color: 'ec4899', // pink
    img: futureImg,
    desc: 'Beating the house comes naturally for you, because you’re a wrecking ball. If you’re always the one betting on red number 69, even though that doesn’t exist I think you found your vibe. Make sure to tip your dealer some doge!'
  },
]