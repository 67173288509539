import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Vibe from '../views/Vibe.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    component: Vibe,
    children: [{
      path: 'atlantis',
      name: 'AtlantisVibe',
      component: () => import('../views/vibes/Atlantis.vue'),
    }, {
      path: 'dojo',
      name: 'DojoVibe',
      component: () => import('../views/vibes/Dojo.vue'),
    }, {
      path: '3022',
      name: 'FutureVibe',
      component: () => import('../views/vibes/Future.vue'),
    }, {
      path: 'moon',
      name: 'MoonVibe',
      component: () => import('../views/vibes/Moon.vue'),
    }, {
      path: 'nightclub',
      name: 'NightclubVibe',
      component: () => import('../views/vibes/Nightclub.vue'),
    }, {
      path: 'trading',
      name: 'TradingVibe',
      component: () => import('../views/vibes/Trading.vue'),
    }, {
      path: 'yacht',
      name: 'YachtVibe',
      component: () => import('../views/vibes/Yacht.vue'),
    }]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
