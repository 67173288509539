<template>
  <div class="js relative overflow-hidden">
    <div class="content">
      <h2 class="content__title font-black" ref="title">
        <span class="content__title-line">
          <span>F</span>
          <span>I</span>
          <span>N</span>
          <span>D</span> 
          <span>&nbsp;</span>
          <span>Y</span>
          <span>O</span>
          <span>U</span>
          <span>R</span>
        </span>
        <span class="content__title-line">
          <span>V</span>
          <span>I</span>
          <span>B</span>
          <span>E</span>
        </span>
      </h2>
      <div class="grid" ref="grid">
        <a v-for="(vibe, idx) in vibes" :key="vibe.name" :href="`#vibe-${idx + 1}`" :class="`grid__item pos-${idx + 1}`" :data-title="`${vibe.name} vibe`">
          <div class="grid__item-img" :style="`background-image:url(${vibe.img});`"></div>
        </a>
      </div>
      <div class="preview">
        <!-- 
        <div v-for="(vibe, idx) in vibes" :key="vibe.name" class="preview__item" :id="`vibe-${idx + 1}`" :style="`--color-vibe: #${vibe.color};`">
          <button class="preview__item-back unbutton"><span>Back</span></button>
          <div class="preview__item-imgwrap">
            <div class="preview__item-img" :style="`background-image:url(${vibe.img});`"></div>
          </div>
          <h2 class="preview__item-title font-black">
            <span v-for="(letter, idx) in vibe.name" :class="`char${idx + 1}`" :key="idx">{{ letter }}</span>
          </h2>
          <div class="preview__item-content">
            <div class="preview__item-meta"><span>Acapulco, Mexico</span><span>15/05/2025</span></div>
            <p class="preview__item-description">{{ vibe.desc }}</p>
            <button class="preview__item-button">Buy Tickets</button>
          </div>
        </div>-->
         <div v-for="(vibe, idx) in vibes" :key="vibe.name" class="vibe__item flex flex-col h-full max-w-5xl mx-auto justify-center" :id="`vibe-${idx + 1}`" :style="`--color-vibe: #${vibe.color};`">
          <div class="vibe__item-back">
            Back
          </div>
          <div class="vibe__item-title font-black">
            <span v-for="(letter, idx) in vibe.name" :class="`char${idx + 1}`" :key="idx">{{ letter }}</span>
          </div>
          <div class="vibe__item-content text-lg max-w-2xl mx-auto mb-12">
            {{ vibe.desc }}
          </div>
          <div class="vibe__item-imgwrap overflow-hidden rounded-xl">
            <img :src="vibe.img" class="vibe__item-img object-cover w-full">
          </div>
        </div>
      </div>
    </div>
    <div class="cursor" ref="cursor">
      <svg class="cursor__svg" width="80" height="80" viewBox="0 0 80 80">
        <circle vector-effect="non-scaling-stroke" class="cursor__svg-circle" cx="40" cy="40" r="20"/>
      </svg>
      <span class="cursor__text text-white font-bold"></span>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import vibes from '@/components/vibes'


import { Cursor } from './cursor';
import { Grid } from './grid';
import { preloadImages } from './utils';

const cursor = ref(null);
const grid = ref(null);
const title = ref(null);

onMounted(() => {
  // custom cursor
  const _cursor = new Cursor(cursor.value, grid.value);


  // Preload  images
  preloadImages('.grid__item-img').then(() => {
      // Remove loader (loading class)
      // document.body.classList.remove('loading');
      
      // Initialize grid
      const _grid = new Grid(grid.value, title.value);
      
      // change cursor text status when hovering a grid item
      _grid.on('mouseEnterItem', itemTitle => _cursor.DOM.text.innerHTML = itemTitle);
      _grid.on('mouseLeaveItem', () => _cursor.DOM.text.innerHTML = '');
  });

  // mouse effects on all links and others
  [...document.querySelectorAll('a, button, .grid__item')].forEach(link => {
      link.addEventListener('mouseenter', () => _cursor.enter());
      link.addEventListener('mouseleave', () => _cursor.leave());
  });
})
</script>

<style scoped>
.vibe__item {
  position: relative;
  margin: 0 auto;
  padding: 0 5vw;
}
.vibe__item:not(.vibe__item--open) {
  position: absolute;
  overflow: hidden;
  height: 0;
  opacity: 0;
}
.vibe__item.vibe__item--open {
  pointer-events: auto;
}
.vibe__item-title {
  font-size: 12vw;
  font-size: clamp(1.5rem,12vw,20vh);
}
.vibe__item-title span {
  color: var(--color-vibe);
  text-shadow: 0 0 5px var(--color-vibe);
}
.vibe__item-imgwrap {
  transform-origin: 50% 100%;
}





*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 18px;
}

body {
  --color-text: #000;
  --color-text-alt: #848484;
  --color-title: #d3d3d3;
  --color-bg: #DCDCDC;
  --color-link: #E3154D;
  --color-link-hover: #000;
  --cursor-stroke: #E3154D;
  --cursor-fill: none;
  --cursor-stroke-width: 1px;
  --cursor-text: #fff;
}

a {
  text-decoration: underline;
  color: var(--color-link);
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
  color: var(--color-link-hover);
  outline: none;
}

.unbutton {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.unbutton:focus {
  outline: none;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
  content: '';
  position: fixed;
  z-index: 1000;
}

.js .loading::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.js .loading::after {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  opacity: 0.4;
  background: var(--color-link);
  animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5,0.5,1);
  }
}

.message {
  position: relative;
  z-index: 100;
  background: var(--color-text);
  color: var(--color-bg);
  padding: 0.5rem;
  font-size: 0.75rem;
  position: relative;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.frame {
  padding: 1rem 5vw;
  position: relative;
  z-index: 1000;
}

.frame__title {
  font-size: 1rem;
  margin: 0 0 1rem;
  font-weight: normal;
}

.frame__links {
  display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
  margin-right: 1rem;
}

.frame__demos {
  margin: 1rem 0;
}

.frame__demo--current,
.frame__demo--current:hover {
  color: var(--color-text);
  text-decoration: none;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 300px;
  justify-content: center;
  position: relative;
  align-items: center;
  overflow: hidden;
}

.grid {
  position: absolute;
  /*width: 120%;
  height: 120%;
  top: -10%;
  left: -10%;*/
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(50,2%);
  grid-template-rows: repeat(50,2%);
  perspective: 1000px;
}

.grid--inactive {
  pointer-events: none;
}

.grid__item {
  position: relative;
  will-change: transform;
  grid-area: var(--grid-row) / var(--grid-column) / span 10 / span 15;
}

.grid__item-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 10px;
  will-change: transform;
}

.char {
  will-change: transform;
}

/* Shorthand grid-area: grid-row-start / grid-column-start / grid-row-end / grid-column-end */

.pos-1 {
  --grid-row: 10;
  --grid-column: 1;
}

.pos-2 {
  --grid-row: 2;
  --grid-column: 17;
}

.pos-3 {
  --grid-row: 10;
  --grid-column: 36;
}

.pos-4 {
  --grid-row: 24;
  --grid-column: 2;
}

.pos-5 {
  --grid-row: 18;
  --grid-column: 18;
}

.pos-6 {
  --grid-row: 23;
  --grid-column: 34;
}

.pos-7 {
  --grid-row: 37;
  --grid-column: 10;
}

.pos-8 {
  --grid-row: 38;
  --grid-column: 28;
}

.pos-9 {
  --grid-row: 36;
    --grid-column: 27;
}

.content__title {
  font-size: 12vw;
  margin: 0;
  line-height: 1.2;
  position: relative;
  text-transform: uppercase;
  pointer-events: none;
}

.content__title-line {
  display: block;
  position: relative;
  overflow: hidden;
  line-height: 1;
  color: #F1F5F9;
}

.preview {
  position: relative;
  pointer-events: none;
}

.js .preview {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.preview__item {
  position: relative;
  margin: 0 auto;
  padding: 0 5vw;
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 50% 50%;
  grid-template-areas: 
  'preview-back preview-back' 
  'preview-img preview-title' 
  'preview-content preview-content';
}

.js .preview__item {
  perspective: 1000px;
}

.js .preview__item:not(.preview__item--open) {
  position: absolute;
  overflow: hidden;
  height: 0;
  opacity: 0;
}

.js .preview__item--open {
  pointer-events: auto;
}

.preview__item-back {
  grid-area: preview-back;
  justify-self: start;
  font-size: 0.857rem;
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 90px;
  margin-bottom: 1rem;
  /*
  TODO: fix ref
  background: url(../img/arrow.svg) no-repeat 0% 50%;*/

}

.preview__item-title {
  grid-area: preview-title;
  font-size: 12vw;
  font-size: clamp(1.5rem,12vw,20vh);
  margin: 0;
  grid-column-start: 1;
  align-self: center;
  padding-left: 1rem;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.preview__item-title span {
  color: var(--color-vibe);
  text-shadow: 0 0 5px var(--color-vibe);
}

.preview__item-imgwrap {
  width: 100px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
  grid-area: preview-img;
  transform-origin: 50% 100%;
}

.preview__item-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.preview__item-content {
  grid-area: preview-content;
  max-width: calc(1100px - 80vh);
  min-height: 440px;
  line-height: 1.3;
}

.preview__item-meta {
  color: var(--color-text-alt);
  text-transform: uppercase;
  font-size: 0.857rem;
  padding: 1rem 0 0;
}

.preview__item-meta span {
  display: block;
}

.preview__item-info {
  display: block;
  color: var(--color-link);
  margin: 1rem 0;
}

.preview__item-button {
  color: #fff;
  border: 0;
  border-radius: 2rem;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  background: #000;
  padding: 1rem 2rem;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 1rem;
}

.preview__item-button:hover,
.preview__item-button:active,
.preview__item-button:focus {
  outline: none;
  background: var(--color-link);
}

.cursor {
  display: none;
}

@media screen and (min-width: 53em) {
  .message {
    display: none;
  }
  .frame {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    display: grid;
    align-content: space-between;
    width: 100%;
    max-width: none;
    height: 100%;
    padding: 1rem 1.5rem;
    grid-gap: 6vw;
    pointer-events: none;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: 'title links ...'
              '... ... ...'
              '... ... ...';
  }
  .frame__title-wrap {
    grid-area: title;
    display: flex;
  }
  .frame__title {
    margin: 0;
  }
  .frame__tagline {
    position: relative;
    margin: 0 0 0 1rem;
    padding: 0 0 0 1rem;
    opacity: 0.5;
  }
  .frame__demos {
    margin: 0;
    grid-area: demos;
    justify-self: end;
  }
  .frame__links {
    grid-area: links;
    padding: 0;
  }
  .frame a {
    pointer-events: auto;
  }
  .content {
    height: 100vh;
  }
  .preview__item {
    height: 100%;
    width: 80vw;
    grid-template-columns: 47% 53%;
    grid-template-rows: minmax(max(5rem, 18vh), 1fr) auto auto 1fr;
    grid-template-areas: 
    '... ...'
    'preview-back ...' 
    'preview-title preview-title' 
    'preview-img preview-content';
  }
  .preview__item-title {
    justify-self: center;
    padding: 0;
  }
  .preview__item-meta {
    padding: 1rem 0;
  }
  .preview__item-info {
    margin: 1rem 0 3rem;
  }
  .preview__item-imgwrap {
    width: 100%;
    height: 100%;
    grid-row-start: 3;
    border-radius: 10px 10px 0 0;
  }
  .preview__item-content {
    padding: 0 0 0 4rem;
  }
}

@media (any-pointer: fine) {
  .cursor {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 9999;
  }

  .cursor__svg {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
  }

  .cursor__svg-circle {
    fill: none;
    stroke: #c026d3;
    stroke-width: 2;
  }

  .cursor__text {
    position: absolute;
    top: 1.875rem;
    left: 80px;
    text-transform: uppercase;
    text-shadow: 0 0 2px #fff;
  }
}
</style>